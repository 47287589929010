.nav-logo {
  margin: 6px 0;
  width: 7%;
}
.nav-bar {
  background-color: transparent !important;
  box-shadow: none !important;
}
%nav-link {
  text-decoration: none;
  margin-right: 40px;
  font-size: 20px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
}
.nav-link-mobile {
  @extend %nav-link;
  width: 100%;
}

.nav-link-desktop {
  @extend %nav-link;
  color: #fff;
}
